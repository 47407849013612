//- ---------------------------------------------------------------
//- ボタン
//- ---------------------------------------------------------------

//- View All
//- ---------------------------------------------------------------
.btn-viewall
  display flex
  align-items center
  hover-opacity()
  .btn-text
    color #000
    +media(pc)
      margin-right 28px
      font-rem(18,,48)
    +media(sp)
      margin-right 14px
      font-rem(14,,48)
  .btn-icon
    position relative
    background #000
    border-radius 50%
    +media(pc)
      size 30
    +media(sp)
      size 23
    svg
      centering()
      fill #fff
      +media(pc)
        size 9
      +media(sp)
        size 6