//- ---------------------------------------------------------------
//- CONTACT
//- ---------------------------------------------------------------
.btn-contact
  a
    display block
    color #fff
    background #111
    hover-opacity()
    +media(pc)
      padding-top 115px
      padding-bottom 118px
      text-align center
    +media(sp)
      padding 84px 30px 86px
  .heading
    display flex
    align-items center
    line-height 1
    +media(pc)
      flex-direction column
      align-items flex-start
      align-items center
    span
      display block
    .en
      font-weight bold
      +media(pc)
        font-rem(42)
        letterspacing(20)
      +media(sp)
        font-rem(35)
        letterspacing(10)
    .ja
      +media(pc)
        margin-top 12px
        font-rem(12)
      +media(sp)
        margin-top 3px
        margin-left 10px
        font-rem(10)
  .text
    +media(pc)
      margin-top 25px
      font-rem(13,28,60)
      font-family $font-notosansjp-yakuhan
    +media(sp)
      margin-top 20px
      font-rem(13,25,50)
      text-align justify