//- ---------------------------------------------------------------
//- よくある質問
//- ---------------------------------------------------------------
.page-faq

  .local-nav
    li
      +media(pc)
        margin-right 36px
  
  .page-contents
    +media(pc)
      padding-bottom 160px
    +media(sp)
      padding-bottom 100px

  .section-contents
    +media(sp)
      margin-top 44px

  .list-faq
  .list-faq-sub
    border-bottom 1px solid #dcdcdc
    >li
      letterspacing(50)
      text-align justify
      box-sizing border-box
      border-top 1px solid #dcdcdc
      .title-container
        position relative
        cursor pointer
        .title
          display inline-block
        .icon
          absolute top 50%
          display block
          background #fff
          border 1px solid #dddddd
          border-radius 50%
          box-sizing border-box
          +media(pc)
            right 20px
            size 40
            margin-top -20px
          +media(sp)
            right 15px
            size 25
            margin-top -13px
          &::before
          &::after
            absolute top 50% left 50%
            content ""
            display block
            width 2px
            margin-left -1px
            background #000
            +media(pc)
              height 10px
              margin-top -5px
            +media(sp)
              height 8px
              margin-top -4px
          &::after
            transform rotate(90deg)
      .contents
        display none
        background #f9f8f8
        .text-container
          border-top 1px solid #dcdcdc
          li
          p
            &:not(:last-child)
              margin-bottom 1em
            &.mb0
              margin-bottom 0px
            &.bold
              font-weight bold
          a
            color $color-red
            hover-opacity()
            &.link-blank
              position relative
              display inline-block
              word-break break-all
              +media(pc)
                padding-right 23px
              +media(sp)
                padding-right 18px
              &::after
                absolute top 50% right
                display block
                content ""
                bg-img($image-path + "activity/icon-blank.svg")
                +media(pc)
                  size 14 12
                  margin-top -5px
                +media(sp)
                  size 11 9
                  margin-top -3px
            &.link-page
              position relative
              +media(pc)
                padding-right 14px
              +media(sp)
                padding-right 20px
              &::before
                absolute top 50% right
                transform rotate(-90deg)
                size 14 8
                display block
                content ""
                bg-img($image-path + "icon-arrow-red.svg")
                background-size cover
                margin-top -3px
                +media(pc)
                  size 12 7
                +media(sp)
                  size 10 6
          ol
            list-style decimal
            padding-left 23px
            margin-bottom 20px
      &.is-shown
        >.title-container
          .icon
            border none
            background #fb2834
            &::after
              background #fff
            &::before
              display none
        >.contents
          display block

    // 第一階層
    >li
      >.title-container
        +media(pc)
          padding 29px 90px 29px 30px
          font-rem(18,32)
        +media(sp)
          padding 19px 55px 19px 15px
          font-rem(15,27,10)
      >.contents
        >.text-container
          +media(pc)
            padding 29px 30px
          +media(sp)
            padding 19px

    // 第二階層
    .list-faq-sub
      border-bottom none
      >li
        .title-container
          background #f9f8f8
          +media(pc)
            padding 19px 95px 19px 32px
          +media(sp)
            padding 18px 50px 15px 9px
          .title
            position relative
            +media(pc)
              padding-left 23px
            +media(sp)
              padding-left 20px
              letterspacing(10)
            &::before
              absolute top left
              content "・"
              display inline-block
              color $color-red
              +media(pc)
                width 25px
                font-rem(22,32)
              +media(sp)
                width 25px
                font-rem(16,26)
          .icon
            +media(pc)
              right 25px
              size 30
              margin-top -15px
            +media(sp)
              right 17px
              size 20
              margin-top -10px
        .contents
          background #f3f3f3
          .text-container
            +media(pc)
              padding 31px 56px 34px 56px
            +media(sp)
              padding 18px 30px 18px 30px

  .section-category
    +media(pc)
      padding-bottom 60px
    +media(sp)
      padding-bottom 36px
