//- ---------------------------------------------------------------
//- 下層共通
//- ---------------------------------------------------------------

//- PAGE HEADING
//- ---------------------------------------------------------------
.page-heading
  background $color-red
  box-sizing border-box
  +media(pc)
    height 470px
    padding-top 250px
  +media(sp)
    height (480/2)px
    padding-top 120px
  .inner
    margin 0 auto
    +media(pc)
      max-width ($size-pc-contents)px
  span
    display block
    color #fff
    line-height 1
  +media(pc)
    .en
      font-rem(72)
    .ja
      margin-top 16px
      font-rem(15,,80)
  +media(sp)
    padding-left 30px
    .en
      font-rem((78/2))
    .ja
      margin-top 12px
      font-rem((22/2),,80)

//- PAGE CONTENTS
//- ---------------------------------------------------------------
.page-contents
  position relative
  margin 0 auto
  +media(pc)
    max-width ($size-pc-contents)px
  +media(sp)
    padding 0 30px

//- section
//- ---------------------------------------------------------------
.section
  .section-inner
    +media(pc)
      padding-top 100px
    +media(sp)
      flex-direction column
      padding-top 65px
  .section-heading
    position relative
    line-height 1
    box-sizing border-box
    +media(pc)
      padding-top 47px
    +media(sp)
      display flex
      align-items center
      padding-top 33px
    &::before
      absolute top left
      content ""
      display block
      height 1px
      background $color-red
      +media(pc)
        width 80px
      +media(sp)
        width 65px
    span
      display block
    .en
      color $color-red
      +media(pc)
        font-rem(42)
      +media(sp)
        font-rem(35)
    .ja
      +media(pc)
        margin-top 14px
        font-rem(12)
      +media(sp)
        margin-top 6px
        margin-left 10px
        font-rem(10)
  .section-contents
    .list-overview
    .list-history
      display flex
      flex-wrap wrap
      border-top 1px solid #dcdcdc
      dt
      dd
        border-bottom 1px solid #dcdcdc
        box-sizing border-box
        +media(pc)
          padding 29px 0
          letterspacing(50)
        +media(sp)
          padding 19px 0 18px
      dt
        width 160px
        padding-left 11px
        color $color-red
        +media(pc)
          width 160px
          padding-left 11px
        +media(sp)
          width 96px
          padding-left 11px
      dd
        text-align justify
        +media(pc)
          width calc(100% - 160px)
        +media(sp)
          width calc(100% - 96px)
  &.layout-flex
    .section-inner
      display flex
    .section-heading
      +media(pc)
        width 320px
    .section-contents
      flex 1

//- LOCAL NAV
//- ---------------------------------------------------------------
.local-nav
  line-height 1
  border-bottom 1px solid #e2e2e2
  +media(pc)
    padding-top 23px
    padding-bottom 22px
  .btn-index
    position relative
    width 100%
    padding 21px 30px 21px
    font-rem(15)
    text-align left
    box-sizing border-box
    .icon
      absolute top 50% right 30px
      size 14
      margin-top -7px
      &::before
      &::after
        absolute top 50% left 50%
        content ""
        display block
        background $color-red
      &::before
        size 3 14
        margin-top -7px
        margin-left -1px
      &::after
        size 14 3
        margin-left -7px
        margin-top -1px
  ul
    flex-wrap wrap
    +media(pc)
      display flex
      max-width ($size-pc-contents)px
      margin 0 auto
    +media(sp)
      display none
      justify-content space-between
      margin 0 30px -1px
      border-top 1px solid #e2e2e2
  li
    position relative
    +media(sp)
      width calc(50% - 12px)
      border-bottom 1px solid #e2e2e2
    a
      display block
      color #000
      box-sizing border-box
      hover-opacity()
      +media(pc)
        font-rem(15,15,60)
        padding 10px 26px 10px 0
      +media(sp)
        size 100%
        font-rem(12,18,50)
        min-height 60px
        display flex
        align-items center
        padding-left 5px
        padding-right 30px
    .icon
      absolute top 50% right
      fill $color-red
      +media(pc)
        size 13 8
        margin-top -4px
      +media(sp)
        size 13 8
        right 4px
        margin-top -4px
  &.is-shown
    .btn-index
      &::before
        display none
    ul
      display flex