opacity($val)
  opacity $val

clearfix()
  +cache('clearfix')
    &::after
      content ""
      display block
      clear both

border-radius($vals...)
  border-radius $vals

box-sizing($val)
  box-sizing $val

box-shadow()
  box-shadow arguments

background-size($vals...)
  background-size $vals

transform($vals...)
  if support-android4
    -webkit-transform $vals
  transform $vals

transform-origin($vals...)
  if support-android4
    -webkit-transform-origin $vals
  transform-origin $vals

transform-style($val)
  if support-android4
    -webkit-transform-style $val
  transform-style $val

backface-visibility($val)
  if support-android4
    -webkit-backface-visibility $val
  backface-visibility $val

perspective($val)
  if support-android4
    -webkit-perspective $val
  perspective $val

perspective-origin($vals...)
  if support-android4
    -webkit-perspective-origin $vals
  perspective-origin $vals

transition($vals...)
  if support-android4
    -webkit-transition $vals
  transition $vals

transition-property($vals...)
  if support-android4
    -webkit-transition-property $vals
  transition-property $vals

transition-duration($vals...)
  if support-android4
    -webkit-transition-duration $vals
  transition-duration $vals

transition-property($vals...)
  if support-android4
    -webkit-transition-property $vals
  transition-property $vals

transition-timing-function($vals...)
  if support-android4
    -webkit-transition-timing-function $vals
  transition-timing-function $vals

animation($vals...)
  if support-android4
    -webkit-animation $vals
  animation $vals

animation-name($vals...)
  if support-android4
    -webkit-animation-name $vals
  animation-name $vals

animation-duration($vals...)
  if support-android4
    -webkit-animation-duration $vals
  animation-duration $vals

animation-timing-function($vals...)
  if support-android4
    -webkit-animation-timing-function $vals
  animation-timing-function $vals

animation-delay($vals...)
  if support-android4
    -webkit-animation-delay $vals
  animation-delay $vals

animation-iteration-count($vals...)
  if support-android4
    -webkit-animation-iteration-count $vals
  animation-iteration-count $vals

animation-direction($vals...)
  if support-android4
    -webkit-animation-direction $vals
  animation-direction $vals

animation-fill-mode($vals...)
  if support-android4
    -webkit-animation-fill-mode $vals
  animation-fill-mode $vals

animation-play-state($vals...)
  if support-android4
    -webkit-animation-play-state $vals
  animation-play-state $vals
