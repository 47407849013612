.page-top

  .btn-viewmore
    btn-square(,,#fff)
    +media(pc)
      size 230 60
    +media(sp)
      size (340/2) (100/2)
    .btn-text
      line-height 1
      +media(pc)
        font-rem(15,,50)
      +media(sp)
        margin-bottom 2px
        font-rem((28/2),,-20)
    .btn-icon
      absolute top 50%
      fill #fff
      transform translateY(-50%)
      +media(pc)
        right 20px
        size 12
      +media(sp)
        right (30/2)px
        size 8

  .section-heading
    display flex
    align-items center
    line-height 1
    .en
      font-weight bold
    +media(pc)
      .en
        font-rem(42)
      .ja
        font-rem(12)
    +media(sp)
      .en
        font-rem(70/2)
      .ja
        font-rem(20/2)

  //- ---------------------------------------------------------------
  //- HERO
  //- ---------------------------------------------------------------
  .section-hero
    position relative
    &::before
      absolute top left
      content ""
      display block
      width 100%
      background $color-red
      +media(pc)
        height 760px
      +media(sp)
        height (1000/2)px
    .section-inner
      +media(pc)
        padding-top 248px
      +media(sp)
        padding-top (306/2)px
    .section-heading
      flex-direction column
      align-items flex-start
      margin 0 auto
      color #fff
      box-sizing border-box
      +media(pc)
        padding-left 99px
        .en
          font-rem(72,75,6)
        .ja
          margin-top 27px
          font-rem(15,,40)
      +media(sp)
        padding-left (34/2)px
        .en
          font-rem((78/2),(84/2),8)
        .ja
          margin-top (24/2)px
          font-rem((22/2),,40)
    .kv
      +media(pc)
        size calc(100% - 80px) 560px
        margin-top 121px
        bg-img($image-path + "pc/kv.jpg", center, center, no-repeat,,cover)
      +media(sp)
        size calc(100% - 45px) (480/2)px
        margin-top (190/2)px
        bg-img($image-path + "kv.jpg", center, center, no-repeat,,cover)
    .scroll
      +media(pc)
        absolute bottom 361px right 33px
        size 12 198
      +media(sp)
        absolute bottom (300/2)px right (38/2)px
        size (12/2) (287/2)
      .text
        absolute top left
        color #fff
      .line
        absolute bottom left 50%
        display block
        width 1px
        background #fff
        overflow hidden
        +media(pc)
          height 98px
          margin-left -2px
        +media(sp)
          height (160/2)px
          margin-left -1px
        &::after
          absolute top left
          content ""
          display block
          width 100%
          background #000
          animation arrow 2.5s ease 0s infinite normal
    .news
      background #fff
      overflow hidden
      +media(pc)
        absolute bottom right 80px
        size 520 80
      +media(sp)
        position relative
        size (660/2) (110/2)
      button 
        background #000
        btn-square(,0)
        absolute top
        +media(pc)
          &::after
            border-radius 0
          &:hover
            &::before
              width 240%
        .btn-icon
          fill #fff
        &.btn-prev
          left 0
          .btn-icon
            svg
              transform rotate(180deg)
              transform-origin 50% 50%
        +media(pc)
          size 80
          .btn-icon
            size 14
          &.btn-next
            left 81px
        +media(sp)
          size (110/2)
          .btn-icon
            size 14
          &.btn-next
            left (112/2)px
      .list-news
        // absolute top left
        // display flex
        height 100%
        overflow hidden
        +media(pc)
          margin-left 161px
        +media(sp)
          margin-left (222/2)px
        li
          height 100%
          font-family $font-hiragino
          -webkit-font-smoothing antialiased
          box-sizing border-box
          +media(pc)
            width 359px
          +media(sp)
            width (438/2)px
          a
            display block
            color #000
            height 100%
            box-sizing border-box
            hover-opacity()
            +media(pc)
              padding 26px 28px 0
            +media(sp)
              padding (10/2)px (31/2)px
          .date
            color #444
            +media(pc)
              margin-bottom 12px
              font-rem(10,10,130)
            +media(sp)
              font-rem((20/2),(20/2),130)
              small-text(.8)
          .text
            font-weight bold
            +media(pc)
              font-rem(12,12,60)
            +media(sp)
              font-rem((20/2),(20/2),60)
              text-line-1()

  //- ---------------------------------------------------------------
  //- Schedule
  //- ---------------------------------------------------------------
  .section-schedule
    .section-inner
      margin 0 auto
      +media(pc)
        max-width ($size-pc-contents)px
        padding-top 157px
        padding-bottom 157px
      +media(sp)
        padding-top (144/2)px
        padding-bottom (144/2)px
    .section-heading
      +media(sp)
        margin-left 28px
      .en
        color $color-red
      .ja
        vertical-align top
        +media(pc)
          margin-left 18px
        +media(sp)
          margin-left 10px
          margin-top 2px
    .btn-viewall
      +media(pc)
        absolute right top 160px
      +media(sp)
        width (180/2)px
        margin 28px 24px 0 auto
    .list-event
      li
        margin-bottom 0

  //- ---------------------------------------------------------------
  //- About us
  //- ---------------------------------------------------------------
  .section-aboutus
    .section-inner
      display flex
      +media(pc)
        height 550px
        margin-top 124px
      +media(sp)
        flex-direction column
        margin-top 71px
    .block
      box-sizing border-box
      &:nth-child(2)
        background $color-red
      +media(pc)
        &:nth-child(1)
          width calc(50% - 80px)
          margin-left 80px
        &:nth-child(2)
          width 50%
          display flex
          flex-direction column
          justify-content center
      +media(sp)
        &:nth-child(1)
          width calc(100% - 45px)
          margin-left auto
          margin-right 0
        &:nth-child(2)
          padding 62px 30px 65px
    .img
      size 100%
      +media(pc)
        bg-img($image-path + "pc/about-img.jpg", top, center, no-repeat,,cover)
      +media(sp)
        height (350/2)px
        bg-img($image-path + "about-img.jpg", top, center, no-repeat,,cover)
    .contents
      color #fff
      margin 0 auto
      +media(pc)
        width 380px
        padding-top 5px
      .section-heading
        +media(pc)
          margin-left 2px
        .ja
          margin-top 2px
          +media(pc)
            margin-left 17px
          +media(sp)
            margin-left 10px
      .lead
        text-align justify
        +media(pc)
          margin-top 34px
          letterspacing(58)
          font-family $font-notosansjp-yakuhan
        +media(sp)
          margin-top 28px
      .btn-viewmore
        +media(pc)
          margin-top 50px
        +media(sp)
          margin 33px auto 0

  //- ---------------------------------------------------------------
  //- Activity
  //- ---------------------------------------------------------------
  .section-activity
    .section-inner
      margin 0 auto
      +media(pc)
        display flex
        justify-content space-between
        max-width ($size-pc-contents)px
        padding-top 146px
        padding-bottom 150px
      +media(sp)
        padding 71px 30px 75px
    .block
      position relative
      box-sizing border-box
      +media(pc)
        &:nth-child(1)
          width 290px
        &:nth-child(2)
          width 520px
    .section-heading
      .en
        color $color-red
        +media(pc)
          letterspacing(30)
      .ja
        +media(pc)
          margin-left 18px
        +media(sp)
          margin-left 12px
          margin-top 1px
    .lead
      text-align justify
      +media(pc)
        margin-top 35px
        font-rem(15,30,40)
      +media(sp)
        margin-top 27px
        font-rem(14,27)
    .btn-viewmore
      +media(pc)
        absolute bottom 204px left
      +media(sp)
        margin 41px auto 0
    .list-activity
      border-top 1px solid #ccc
      +media(pc)
        margin-top 4px
      +media(sp)
        margin-top 42px
      li
        border-bottom 1px solid #ccc
        box-sizing border-box
        a
          position relative
          display block
          hover-opacity()
          +media(pc)
            padding 36px 50px
          +media(sp)
            padding 25px 32px
          &::before
            absolute top 50%
            content ""
            display block
            border-radius 50%
            background $color-red
            transform translateY(-50%)
            +media(pc)
              left 22px
              size 6
            +media(sp)
              left 15px
              size 5
        .text
          color #000
          line-height 1
          letterspacing(150)
          +media(pc)
            margin-right 30px
            font-rem(18)
          +media(sp)
            margin-right 30px
            font-rem(15)
        .icon
          absolute top 50%
          background #000
          border-radius 50%
          transform translateY(-50%)
          +media(pc)
            right 20px
            size 24
          +media(sp)
            right 15px
            size 18
          svg
            centering()
            fill #fff
            +media(pc)
              size 9
            +media(sp)
              size 5

  //- ---------------------------------------------------------------
  //- Member
  //- ---------------------------------------------------------------
  .section-member
    background rgba(#e1e4e6, .4)
    .section-inner
      margin 0 auto
      +media(pc)
        display flex
        align-items center
        justify-content space-between
        max-width ($size-pc-contents)px
        padding 80px 0
      +media(sp)
        padding 60px 30px 65px
    .section-heading
      margin-left -2px
      +media(pc)
        margin-top 3px
      .en
        color $color-red
        +media(sp)
          letterspacing(-16)
      .ja
        +media(pc)
          margin-left 21px
        +media(sp)
          margin-left 12px
          margin-top 4px
    .list-member
      display flex
      +media(sp)
        margin-top 34px
        justify-content space-between
      li
        +media(pc)
          size 250 90
          margin-left 20px
        +media(sp)
          height 75px
          width calc(50% - 7px)
        a
          btn-square()
          align-items flex-start
          size 100%
          +media(pc)
            padding-left 29px
          +media(sp)
            padding-left 20px
          .btn-text
            color #fff
            +media(pc)
              font-rem(16,,150)
            +media(sp)
              font-rem(14,,80)
          .btn-icon
            absolute top 50%
            transform translateY(-50%)
            fill #fff
            +media(pc)
              right 29px
              size 12
            +media(sp)
              right 17px
              size 8

  //- ---------------------------------------------------------------
  //- list-btn
  //- ---------------------------------------------------------------
  .list-btn
    +media(pc)
      display flex
    li
      hover-opacity()
      +media(pc)
        width 50%
        min-height 330px
      a
        position relative
        display block
        size 100%
        color #fff
        box-sizing border-box
        +media(pc)
          display flex
          justify-content center
          padding-top 89px
        +media(sp)
          padding 60px 30px 0
      .heading
        position relative
        display flex
        align-items center
        line-height 1
        +media(pc)
          margin-left 2px
        .en
          font-weight bold
          +media(pc)
            font-rem(42)
          +media(sp)
            font-rem(35)
            letterspacing(-20)
        .ja
          +media(pc)
            margin-left 16px
            margin-bottom 2px
            font-rem(12)
          +media(sp)
            margin-left 13px
            margin-top 3px
            font-rem(10)
        .icon
          fill #fff
          +media(pc)
            absolute top 10px right 2px
            size 17
          +media(sp)
            absolute top 14px right 2px
            size 15
      .contents
        +media(pc)
          width 280px
      .text
        display flex
        flex-direction column
        justify-content center
        text-align justify
        +media(pc)
          height 96px
          margin-top 16px
          font-rem(13,24,52)
          font-family $font-notosansjp-yakuhan
        +media(sp)
          margin-top 20px
          font-rem(13,24,-50)
      &.btn-join
        background $color-red
        a
          +media(sp)
            padding-bottom 62px
        .heading
          .ja
            letterspacing(60)
      &.btn-faq
        background #d0000c
        a
          +media(sp)
            padding-bottom 60px
        .heading
          .en
            letterspacing(50)
          .ja
            margin-top 0
            letterspacing(40)
            +media(sp)
              margin-left 8px
              letterspacing(60)
          .icon
            top 10px