//- ---------------------------------------------------------------
//- 会員一覧
//- ---------------------------------------------------------------
.page-members

  .local-nav
    li
      +media(pc)
        margin-right 58px

  .page-contents
    +media(pc)
      padding-bottom 85px
    +media(sp)
      padding-bottom 66px
  .section-contents
    +media(pc)
      padding-top 60px
      padding-bottom 40px
    +media(sp)
      padding-top 45px
      padding-bottom 23px

  .section-supporting
    .section-heading
      display block
      .ja
        margin-left 0
        margin-top 16px
    .section-contents
      +media(sp)
        padding-top 50px

.list-members
  display flex
  flex-wrap wrap
  img
    width 100%
  li
    +media(pc)
      width 160px
      margin-bottom 20px
    +media(sp)
      width (100/3)%
      margin-bottom 10px