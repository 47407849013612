//- ---------------------------------------------------------------
//- 活動報告
//- ---------------------------------------------------------------
.page-activity

  .local-nav
    li
      +media(pc)
        margin-right 60px
    
  .page-contents
    +media(pc)
      padding-bottom 160px
    +media(sp)
      padding-bottom 100px
      
  .list-article
    +media(pc)
      margin-top 47px
    +media(sp)
      margin-top 42px

  .section-contents
    +media(pc)
      padding-top 50px
    +media(sp)
      padding-top 37px
    .lead
      +media(pc)
        position relative
        padding-bottom 4px
      .text
        +media(pc)
          letterspacing(50)
      .btn-viewall
        absolute bottom right
        +media(pc)
          bottom 0
        +media(sp)
          bottom 32px

  // Seminar 定例会
  .section-seminar
    .section-inner
      +media(pc)
        padding-bottom 60px
      +media(sp)
        padding-bottom 88px

  // Subcommittee 分科会
  .section-subcommittee
    .section-inner
      +media(pc)
        padding-bottom 61px
      +media(sp)
        padding-bottom 35px
    .list-subcommittee
      display flex
      flex-wrap wrap
      +media(pc)
        margin-right -80px
        margin-top -53px
      +media(sp)
        margin-top 44px
      >li
        +media(pc)
          width 440px
          margin-top 100px
          margin-right 80px
        +media(sp)
          &:not(:first-child)
            margin-top 50px
        .btn
          position relative
          display block
          hover-opacity()
          .img
            img
              width 100%
              +media(pc)
                border-radius 5px
              +media(sp)
                border-radius 5px
          .contents
            absolute top left
            size 100%
            display flex
            align-items center
            box-sizing border-box
            +media(pc)
              padding-left 40px
            +media(sp)
              padding-left 25px
            .btn-text
              color #fff
              font-weight bold
              +media(pc)
                font-rem(22,32,25)
              +media(sp)
                font-rem(18,30,)
            .btn-icon
              absolute top 50%
              background #fff
              border-radius 50%
              +media(pc)
                right 30px
                size 24
                margin-top -12px
              +media(sp)
                right 25px
                size 24
                margin-top -12px
              svg
                centering()
                +media(pc)
                  size 8
                +media(sp)
                  size 8
        .text
          text-align justify
          +media(pc)
            margin-top 24px
            font-rem(13,26,25)
          +media(sp)
            margin-top 24px
            font-rem(14,27)
        .list-subcommittee-article
          border-top 1px solid #dcdcdc
          +media(pc)
            margin-top 23px
          +media(sp)
            margin-top 32px
          li
            border-bottom 1px solid #dcdcdc
            +media(pc)
              padding-top 20px
              padding-bottom 16px
            +media(sp)
              padding-top 22px
              padding-bottom 17px
            a
              display block
              color #000
              hover-opacity()
            .date
              letterspacing(50)
              +media(pc)
                font-rem(12,12)
              +media(sp)
                font-rem(10,10)
            .title
              font-weight bold
              text-align justify
              +media(pc)
                margin-top 4px
                text-line-1()
                font-rem(15,32,50)
              +media(sp)
                margin-top 10px
                font-rem(14,23,25)

  // Event イベント
  .section-meetup
    .section-inner
      +media(pc)
        padding-bottom 60px
      +media(sp)
        padding-bottom 75px
    .lead
      .btn-viewall
        +media(sp)
          bottom 20px

  // Proposal 政策提言
  .section-proposal
    .section-inner
      +media(sp)
        padding-bottom 53px
    .section-heading
      +media(sp)
        flex-direction column
        align-items flex-start
        .ja
          margin-top 10px
          margin-left 0
    .section-contents
      +media(sp)
        padding-top 43px
    .lead
      .btn-viewall
        +media(sp)
          bottom 0