global-reset()

html
  font-size 62.5%
body
  font-family $font-family-base
  font-weight 400
  color $font-color-base
  background-color $base-color
  -webkit-font-smoothing antialiased
  -webkit-text-size-adjust 100%
  +media(pc)
    font-rem(15,32)
  +media(sp)
    font-rem((28/2),(54/2))
  button
    border none
    outline none
    padding 0
    cursor pointer
    background none
    border-radius 0
    font-family $font-family-base
    -webkit-tap-highlight-color rgba(0,0,0,0)
  .icon
    display block
  a
    text-decoration none
  img
    vertical-align middle
    +media(sp)
      width 100%

.for-sp
  display none
  +media(sp)
    display inline-block
.for-pc
  +media(sp)
    display none

.en
  font-roboto()

.wrapper
  position relative
  overflow hidden
  +media(pc)
    min-width ($size-pc-contents)px
  svg
    display block
    size 100%

.section-inner
  position relative

.stage
  position fixed
  width 100vw
  // max-width 
  height 24vw
  z-index -1
  transform rotate(45deg)
  // background #ccc

  &.right
    top 20vw
    left calc(50% - 20vw)
    margin-top -12vw
    // margin-left -50vh
    transform-origin center center
    
  &.left
    bottom 20vw
    right calc(50% - 20vw)
    margin-bottom -12vw
    transform-origin center center


