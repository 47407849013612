//- ---------------------------------------------------------------
//- ログイン
//- ---------------------------------------------------------------
.page-login.page-form
  .page-contents
    padding-top 0
  .form-container
    +media(pc)
      padding-top 80px
      padding-bottom 47px
    +media(sp)
      padding-top 45px
      padding-bottom 36px

.page-article-event
  .page-contents
    padding-top 0
  .form-container
      a
        &#login
          display none
      +media(pc)
        width 960px
        margin-top 60px !important
        margin-left -120px
        padding-bottom 47px
      +media(sp)
        margin-top 28px !important
        padding-top 0
        padding-bottom 36px

.page-login.page-form
.page-article-event
    .form-container
      legend
        display none
      fieldset

        // アカウント名、パスワード
        label[for="log"]
        label[for="pwd"]
          font-weight bold
          +media(pc)
            float left
            size 240 70
            margin-bottom 40px
            line-height 70px
            display block
            box-sizing border-box
        
        // 入力フィールド
        .div_text
          +media(pc)
            float right
            size 720 70
            margin-bottom 40px
            overflow hidden
          +media(sp)
            margin-top 11px
            margin-bottom 31px

      .button_div
        position relative
        clear both
        margin 0 auto
        +media(pc)
          width 480px
        +media(sp)
          width 100%

      // ログインボタン
      .btn-login
        btn-square()
        width 100%
        letterspacing(120)
        margin 0 auto
        +media(pc)
          height 100px
          margin-top 28px
          font-rem(16)
        +media(sp)
          height 70px
          margin 0
          font-rem(13)
        input
          color #fff
        &:hover
          input
            z-index 1
            display block
            color #000

      // ログイン情報を保存
      label[for="rememberme"]
        absolute left
        cursor pointer
        overflow hidden
        display inline-block
        box-sizing border-box
        line-height 1
        +media(pc)
          bottom -30px
          padding-left 25px
          font-rem(14)
        +media(sp)
          bottom -26px
          padding-left 20px
          font-rem(12)
        &::before, &::after
          content ''
        &::before
          absolute top left
          border 1px solid #acacac
          z-index 3
          +media(pc)
            size 12
          +media(sp)
            size 10
        &::after
          absolute top 40% left 4px
          display block
          size 4 7
          border-right 2px solid #acacac
          border-bottom 2px solid #acacac
          transform rotate(45deg)
          z-index 1
          +media(pc)
            size 4 7
            margin-top -5px
          +media(ie11)
            margin-top -4px
          +media(sp)
            size 3 6
            margin-top -4px
        input
          appearance none
          absolute left -40px
          size 20px
          display block
          box-shadow 40px 0px #FFF
          z-index 2
          margin 0
          padding 0
          &:checked
            box-shadow none
          &:checked:focus
            opacity 0.1

      // ログアウト
      .text-logout
        margin 0 auto
        +media(pc)
          width 480px
      .btn-logout
        btn-square()
        margin 0 auto
        letterspacing(120)
        a
          display flex
          justify-content center
          align-items center
          color #fff
          size 100%
        +media(pc)
          size 480 100
          margin-top 50px
          font-rem(16)
          &:hover
            a
              z-index 1
              color #000
        +media(sp)
          size 100% 70px
          margin-top 30px
          font-rem(13)