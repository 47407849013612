//- ---------------------------------------------------------------
//- イベント
//- ---------------------------------------------------------------

.list-event
  ul
    display flex
    flex-wrap wrap
    +media(pc)
      margin-top 60px
      margin-right -45px
    +media(sp)
      margin-top 44px
      flex-direction column
  li
    position relative
    border 1px solid #ccc
    border-radius 5px
    box-sizing border-box
    +media(pc)
      width 290px
      margin-right 45px
      margin-bottom 50px
    +media(sp)
      width 100%
      margin-bottom 25px
    a
      display block
      color #111
      hover-opacity()
      +media(pc)
        padding 48px 40px 42px
      +media(sp)
        padding 27px 30px 25px
    .date
      line-height 1
      letterspacing(90)
      +media(pc)
        font-rem(13)
        margin-left 2px
      +media(sp)
        font-rem(11)
        margin-left -2px
    .tag
      color #fff
      font-weight bold
      box-sizing border-box
      +media(pc)
        absolute top 43px right 40px
        padding 7px 9px 7px 13px
        font-rem(10,10,25)
        border-radius 12px
      +media(sp)
        absolute top 24px right 29px
        padding 5px 0 3px 7px
        font-rem(10,10,40)
        border-radius 10px
        span
          margin-right -7px
          small-text(.8)
    .text
      font-weight bold
      text-align justify
      +media(pc)
        margin-top 31px
        font-rem(18,32)
      +media(sp)
        margin-top 17px
        font-rem(14,24)
    &.event-in
      .date
        color $color-red
      .tag
        background $color-red
    &.event-out
      .date
        color $color-blue
      .tag
        background $color-blue

.page-top
  .list-event
    +media(sp)
      width 100%
      overflow-x auto
      overflow-y hidden
      -webkit-overflow-scrolling touch
      &::-webkit-scrollbar
        display none
        height 0 !important
    ul
      margin-top 63px
      +media(sp)
        flex-direction row
        width vw-sp(1380)
        margin-top (70/2)px
        margin-left (60/2)px
        -webkit-overflow-scrolling touch
        & > *
          -webkit-transform translateZ(0px)
    li
      +media(sp)
        width calc(100% / 3 - 20px)
        margin-right 20px
      a
        +media(sp)
          padding 27px 30px 33px
