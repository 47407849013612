//- ---------------------------------------------------------------
//- 当協会について
//- ---------------------------------------------------------------

.page-aboutus

  .local-nav
    +media(pc)
      ul
        justify-content space-between
        li
          a
            padding-right 19px

  .page-contents
    +media(pc)
      padding-top 60px
      padding-bottom 160px
    +media(sp)
      padding-bottom 100px

  //- Overview 概要
  .section-overview
    .section-inner
      +media(pc)
        padding-bottom 52px
      +media(sp)
        padding-bottom 30px
    .section-contents
      +media(sp)
        padding-top 45px
      .notes
        +media(pc)
          font-rem(13,32,50)
          margin-top 29px
        +media(sp)
          margin-top 19px
          font-rem(10,20,50)

  //- Greetings 代表挨拶
  .section-greetings
    .section-inner
      +media(pc)
        padding-bottom 52px
      +media(sp)
        padding-bottom 28px
    .section-contents
      letterspacing(50)
      +media(sp)
        padding-top 44px
      .profile
        position relative
        box-sizing border-box
        .block
          absolute top left
          +media(pc)
            padding-top 110px
            padding-left 89px
          +media(sp)
            padding-top vw-sp(122)
            padding-left vw-sp(54)
          .name
            line-height 1
            color $color-red
            +media(pc)
              font-rem(26)
            +media(sp)
              font-vw(40,,180)
              small-text(.9)
            .sub
              display block
              color #000
              +media(pc)
                margin-left 2px
                margin-bottom 11px
                font-rem(13)
              +media(sp)
                margin-left vw-sp(4)
                margin-bottom vw-sp(8)
                small-text(.9)
                display block
                font-vw(20,32,70)
          .position
            +media(pc)
              margin-top 22px
              margin-left 1px
              font-rem(13,24)
            +media(sp)
              margin-top vw-sp(4)
              margin-left vw-sp(4)
              small-text(.9)
              display block
              font-vw(20,36,70)
      .text
        text-align justify
        +media(pc)
          margin-top 30px
        +media(sp)
          margin-top 24px

  //- Officer 役員紹介
  .section-officer
    .section-inner
      +media(pc)
        margin-bottom -19px
      +media(sp)
        margin-bottom -16px
    .section-contents
      +media(sp)
        padding-top 46px
      .list-officer
        display flex
        flex-wrap wrap
        +media(pc)
          margin-right -60px
        +media(sp)
          margin-right -25px
        li
          +media(pc)
            width 290px
            margin-right 60px
            margin-bottom 76px
          +media(sp)
            width calc(50% - 25px)
            margin-right 25px
            margin-bottom 48px
          .position
            +media(pc)
              margin-top 28px
              font-rem(10,16,50)
            +media(sp)
              margin-top 16px
              font-rem(10,18,-2)
              small-text(.8)
              width 125%
          .name
            color $color-red
            +media(pc)
              margin-top 8px
              font-rem(20,24,50)
            +media(sp)
              margin-top -4px
              font-rem(16,24,-20)
          &:nth-child(1)
            +media(sp)
              margin-bottom 0
              .name
                margin-top -8px

  //- Adviser 役員紹介
  .section-adviser
    .section-inner
      +media(pc)
        padding-bottom 7px
      +media(sp)
        padding-bottom 20px
    .section-heading
      .ja
        +media(pc)
          margin-top 7px
          font-rem(12,20)
        +media(sp)
          margin-top 7px
          font-rem(10,13)
    .section-contents
      +media(pc)
        padding-top 58px
      +media(sp)
        padding-top 34px
      .list-adviser
        display flex
        flex-wrap wrap
        +media(pc)
          margin-right -60px
        +media(sp)
          flex-direction column
        li
          +media(pc)
            width 350px
            min-height 130px
          +media(sp)
            width 100%
            margin-bottom 13px
          .position
            +media(pc)
              font-rem(10,18,50)
            +media(sp)
              font-rem(10,16,-2)
              small-text(.8)
              width 125%
          .name
            color $color-red
            +media(pc)
              font-rem(20,32,50)
            +media(sp)
              margin-top -1px
              font-rem(17,21,50)

  //- Staff 事務局スタッフ
  .section-staff
    .section-inner
      +media(pc)
        padding-bottom 60px
      +media(sp)
        padding-bottom 36px
    .section-contents
      .caption
        display flex
      +media(pc)
        .caption
          .staff-1
            margin-left 116px
          .staff-2
            margin-left 94px
          .staff-3
            margin-left 70px
          .staff-4
            margin-left 38px
          .staff-5
            margin-left 38px
      +media(sp)
        padding-top 45px
        .caption
          text-align center
          justify-content flex-end
          font-rem(13)
          span
            box-sizing border-box
          .staff-1
            width _percentage(144,630)
          .staff-2
            width _percentage(120,630)
          .staff-3
            width _percentage(104,630)
          .staff-4
            width _percentage(130,630)
          .staff-5
            width _percentage(120,630)
            padding-right _percentage(4,120)
            
          // .staff-3
          //   margin-left 70px
          // .staff-4
          //   margin-left 38px
          // .staff-5
          //   margin-left 38px

  //- History 沿革
  .section-history
    .section-inner
      +media(pc)
        padding-bottom 60px
      +media(sp)
        padding-bottom 36px
    .section-contents
      +media(sp)
        padding-top 45px

  //- Definition ブロックチェーンとは
  .section-definition
    .section-inner
      +media(pc)
        padding-bottom 51px
      +media(sp)
        padding-bottom 28px
    .section-contents
      +media(pc)
        padding-top 60px
      +media(sp)
        padding-top 45px
      .list-definition
        display flex
        justify-content space-between
        +media(sp)
          flex-direction column
        li
          +media(pc)
            width 290px
          .tag
            display inline-block
            color #fff
            font-weight bold
            background $color-red
            border-radius 10px
            +media(pc)
              margin-top 30px
              padding 5px 12px 5px 16px
              font-rem(10,10)
            +media(sp)
              margin-top 25px
              padding 6px 0 4px 14px
              font-rem(10,10,100)
              span
                small-text(.8)
                margin-right -20px
            span
              display inline-block
          .text
            letterspacing(50)
            text-align justify
            +media(pc)
              margin-top 17px
            +media(sp)
              margin-top 15px
          +media(sp)
            &:not(:last-child)
              margin-bottom 42px
            &:nth-child(2)
              .tag
                span
                  margin-right -14px

  //- Partner パートナー情報
  .section-partner
    .section-inner
      +media(pc)
        padding-bottom 60px
      +media(sp)
        padding-bottom 35px
    .section-contents
      +media(pc)
        padding-top 51px
      +media(sp)
        padding-top 38px
      >.text
        letterspacing(40)
        text-align justify
      .btn
        btn-square(,,#fff)
        line-height 1
        letterspacing(100)
        +media(pc)
          size 230 60
          margin-top 41px
          padding-right 12px
          font-rem(14)
        +media(sp)
          size 240 50
          margin 42px auto 0
          font-rem(13)
        .btn-text
          margin-bottom 2px
        .btn-icon
          absolute top 50% right 20px
          fill #fff
          size 12 10
          margin-top -5px

  //- Join us 入会案内
  .section-joinus
    .section-contents
      +media(pc)
        padding-top 59px
      +media(sp)
        padding-top 38px
      .heading
        position relative
        +media(pc)
          font-rem(20,24,50)
          padding-left 19px
        +media(sp)
          font-rem(16,27,20)
          padding-left 15px
        &::before
          absolute top 50% left
          content ""
          display block
          background $color-red
          border-radius 50%
          +media(pc)
            size 6
            margin-top -3px
          +media(sp)
            size 5
            margin-top -2px
      .notes
        +media(pc)
          font-rem(13,28,50)
        +media(sp)
          font-rem(10,20,40)
          text-align justify
        li
          display flex
          span
            display inline-block
            +media(pc)
              margin-right 10px
            +media(sp)
              margin-right 7px

      .section-1
        .block-container
          display flex
          +media(sp)
            flex-direction column
          .block
            box-sizing border-box
            &:nth-child(1)
              display flex
              flex-wrap wrap
              align-items center
              justify-content center
              border-bottom 1px solid #dcdcdc
              +media(pc)
                width 121px
                border-right 1px solid #dcdcdc
              +media(sp)
                padding-top 10px
                padding-bottom 8px
                font-rem(12,27,50)
            &:nth-child(2)
              flex 1
          dl
            display flex
            flex-wrap wrap
            dt
            dd
              display flex
              flex-wrap wrap
              flex-direction column
              justify-content center
              box-sizing border-box
              border-bottom 1px solid #dcdcdc
              +media(pc)
                padding-top 25px
                padding-bottom 25px
                font-rem(14,28,50)
              +media(sp)
                padding-top 21px
                padding-bottom 22px
                font-rem(12,22,50)
            dt
              +media(pc)
                align-items center
                text-align center
                width 139px
              +media(sp)
                width 110px
                padding-left 10px
            dd
              border-left 1px solid #dcdcdc
              +media(pc)
                width 380px
                padding-left 28px
              +media(sp)
                width calc(100% - 110px)
                padding-left 23px
                font-rem(12,19,50)
                p
                  &:not(:last-child)
                    margin-bottom 10px
          &:nth-of-type(1)
            border-top 1px solid #dcdcdc
            +media(pc)
              margin-top 48px
            +media(sp)
              flex-direction column
              margin-top 25px
          &:nth-of-type(2)
            dl
              dt
              dd
                +media(sp)
                  padding-top 20px
                  padding-bottom 18px
        .table
          width 100%
          border-top 1px solid #dcdcdc
          +media(pc)
            margin-top 48px
          +media(sp)
            margin-top 24px
          th
            position relative
            text-align center
            background #f6f6f6
            +media(pc)
              padding-top 20px
              padding-bottom 20px
            +media(sp)
              padding-top 14px
              padding-bottom 14px
          th
          td
            position relative
            text-align center
            box-sizing border-box
            border-bottom 1px solid #dcdcdc
            letterspacing(50)
            +media(pc)
              width 150px
              font-rem(14,20)
            +media(sp)
              width 66px
              font-rem(10,17)
            &:not(:last-child)
              border-right 1px solid #dcdcdc
            &:first-child
              width auto
            span
              margin-top 5px
              +media(pc)
                font-rem(12,14)
              +media(sp)
                font-rem(9,17)

          td
            &:not(:first-child)
              +media(pc)
                padding-top 30px
                padding-bottom 30px
              +media(sp)
                padding-top 22px
                padding-bottom 21px
            &:first-child
              padding-left 10px
              padding-right 10px
              span
                margin-top 1em
                display inline-block
                +media(pc)
                  font-rem(12,19)
                +media(sp)
                  font-rem(9,17)


        .notes
          +media(pc)
            margin-top 46px
          +media(sp)
            margin-top 20px

      .section-2
        +media(pc)
          margin-top 111px
        +media(sp)
          margin-top 64px
        .table
          width 100%
          border-top 1px solid #dcdcdc
          +media(pc)
            margin-top 48px
          +media(sp)
            margin-top 24px
          th
            position relative
            text-align center
            background #f6f6f6
            +media(pc)
              padding-top 20px
              padding-bottom 20px
            +media(sp)
              padding-top 14px
              padding-bottom 14px
          th
          td
            position relative
            text-align center
            box-sizing border-box
            border-bottom 1px solid #dcdcdc
            letterspacing(50)
            +media(pc)
              width 150px
              font-rem(14,20)
            +media(sp)
              width 66px
              font-rem(10,17)
            &:not(:last-child)
              border-right 1px solid #dcdcdc
            &:first-child
              width auto
              text-align left
          td
            &:not(:first-child)
              +media(pc)
                padding-top 30px
                padding-bottom 30px
              +media(sp)
                padding-top 22px
                padding-bottom 21px
            &:first-child
              padding-left 10px
              padding-right 10px
          .icon-item
            position relative
            display inline-block
            .icon
              +media(pc)
                font-rem(10,10)
                small-text(.8)
              +media(sp)
                font-rem(10,10)
                small-text(.8)
          th
            .icon-item
              .icon
                +media(pc)
                  absolute top -4px right -11px
                +media(sp)
                  absolute top -10px right
          td
            .icon-item
              .icon
                +media(pc)
                  absolute top -2px right -14px
                +media(sp)
                  absolute top -4px right -10px
          .icon-blank
            display block
            margin 0 auto
            background #000
            +media(pc)
              size 9 1
            +media(sp)
              size 9 1
        .notes
          +media(pc)
            margin-top 42px
          +media(sp)
            margin-top 20px
        .list-merit
          position relative
          +media(pc)
            margin-top 48px
          +media(sp)
            margin-top 34px
          ol
            position relative
            z-index 1
          li
            display flex
            border-bottom 1px solid #dcdcdc
            padding-bottom 50px
            +media(pc)
              margin-bottom 44px
            +media(sp)
              flex-direction column
              margin-bottom 37px
            a
              hover-opacity()
              color $color-red
            .img
              background #fff
              +media(pc)
                width 124px
                margin-right 35px
              +media(sp)
                width 140px
                margin 0 auto
              img
                width 100%
            .block
              flex 1
              +media(sp)
                margin-top 24px
            .title
              line-height 1
              display inline-block
              color #fff
              font-weight bold
              background $color-red
              border-radius 20px
              +media(pc)
                margin-top 5px
                margin-right 17px
                padding 8px 20px 10px 20px
                font-rem(16)
              +media(sp)
                margin-right 13px
                padding 5px 14px 5px 14px
                font-rem(16)
            .text
              letterspacing(50)
              text-align justify
              +media(pc)
                margin-top 14px
              +media(sp)
                margin-top 19px
              .red
                color #fb2834
                font-weight bold


      .section-3
        +media(pc)
          margin-top 145px
        +media(sp)
          margin-top 64px
        .list-flow
          position relative
          +media(pc)
            margin-top 48px
          +media(sp)
            margin-top 34px
          .line
            +media(pc)
              absolute top 10px left 60px
              background-image linear-gradient(to bottom, #fb2834, #fb2834 2px, transparent 2px)
              background-size 1px 4px
              size 1 90%
          ol
            position relative
            z-index 1
          li
            display flex
            +media(pc)
              margin-bottom 40px
            +media(sp)
              flex-direction column
              margin-bottom 37px
            a
              hover-opacity()
              color $color-red
            .img
              background #fff
              +media(pc)
                width 124px
                margin-right 35px
              +media(sp)
                width 140px
                margin 0 auto
              img
                width 100%
            .block
              flex 1
              +media(sp)
                margin-top 24px
            .title
              display flex
              align-items center
              // align-items flex-start
              line-height 1
              +media(pc)
                margin-top 16px
              +media(sp)
                font-rem(16)
              .tag
                display inline-block
                color #fff
                font-weight bold
                background $color-red
                border-radius 11px
                line-height 1
                +media(pc)
                  margin-right 17px
                  padding 5px 16px 5px 20px
                  font-rem(12)
                +media(sp)
                  margin-right 13px
                  padding 5px 8px 5px 14px
                  font-rem(10)
                  span
                    small-text(.9)
                span
                  display inline-block
            .text
              letterspacing(50)
              text-align justify
              +media(pc)
                margin-top 14px
              +media(sp)
                margin-top 19px

      .section-4
        +media(pc)
          margin-top 118px
        +media(sp)
          margin-top 61px
        >.text
          +media(pc)
            letterspacing(18)
            text-align justify
            margin-top 21px
          +media(sp)
            margin-top 18px
        .btn
          btn-square($color-red,,#fff)
          font-weight bold
          +media(pc)
            margin-top 40px
            size 480 80
          +media(sp)
            margin-top 43px
            size 100% 70
          .btn-text
            letterspacing(100)
            +media(pc)
              font-rem(16)
            +media(sp)
              font-rem(14)
          .btn-icon
            fill #fff
            +media(pc)
              absolute top 50% right 30px
              size 12
              margin-top -6px
            +media(sp)
              absolute top 50% right 20px
              size 8
              margin-top -4px

  //- AOI 定款
  .section-aoi
    .section-contents
      a
        hover-opacity()
        color $color-red
      +media(pc)
        padding-top 50px
      +media(sp)
        padding-top 30px