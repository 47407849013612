/*!
Theme Name: Japan Blockchain Association
Description: Japan Blockchain Association
Version: 1.0
Author: Japan Blockchain Association
*/

@charset "UTF-8"

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700|Roboto:500,700&display=swap')
@import url('https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/css/yakuhanjp.min.css')
@import 'stylus/imports/fonts'
@import 'stylus/imports/variables'
@import 'stylus/imports/setting'
@import 'stylus/imports/sprite'
@import 'stylus/imports/function'
@import 'stylus/imports/mixin'
@import 'stylus/imports/nib-normalize'
@import 'stylus/imports/keyframes'
@import 'stylus/imports/base'
@import 'stylus/imports/swiper'
@import 'stylus/imports/layout/*'
@import 'stylus/imports/pages/*'
@import 'stylus/imports/module/*'