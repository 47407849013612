//- ---------------------------------------------------------------
//- HEADER
//- ---------------------------------------------------------------
.header
  width 100%
  z-index 4
  +media(pc)
    absolute top left
    height 150px
  +media(sp)
    fixed top left
    height (150/2)px
  .header-inner
    +media(pc)
      padding-top 32px
  .logo
    absolute top left
    display inline-block
    z-index 1
    a
      display block
    +media(sp)
      width (160/2)px
  +media(sp)
    .is-menu-opened &
      height 100%
      .header-inner
        height 100%

// メニューボタン
.btn-menu
  fixed top (26/2)px right (26/2)px
  size (100/2)
  padding-top 6px
  box-sizing border-box
  z-index 3
  +media(pc)
    display none
  span
    display block
  .icon
    position relative
    size (52/2) (38/2)
    margin 0 auto
    span
      absolute top 50% left
      display block
      content ""
      size (52/2) 3
      background #fff
      transition .2s
      &:nth-child(1)
        margin-top -8px
      &:nth-child(3)
        margin-top 8px
  .text
    color #fff
    margin-top 6px
    font-rem(10,10)
  .is-menu-opened &
    top 4px
    &.is-menu-black
      .icon
        span
          background #fff
      .text
        color #fff
    .icon
      span
        &:nth-child(1)
          margin-top 0
          transform rotate(45deg)
        &:nth-child(2)
          opacity 0
        &:nth-child(3)
          margin-top 0
          transform rotate(135deg)
    .text
      display none
  &.is-menu-black
    .icon
      span
        background #000
    .text
      color #000

// メニュー
.menu
  position relative
  display flex
  flex-direction column
  align-items flex-end
  width 100%
  box-sizing border-box
  +media(pc)
    padding 0 40px
  +media(sp)
    height 100%
    padding-top 62px
    padding-bottom 50px
    background $color-red
    display none
    overflow-y auto
    .is-menu-opened &
      display flex
      display block
      z-index 2
  a
    position relative
    display block
    color #fff
    font-weight bold
    line-height 1
    &.disabled
      color #ff9999
      pointer-events none
      .btn-icon
        display none !important
    +media(pc)
      z-index 2

  .block
    display flex
    box-sizing border-box
    +media(pc)
      align-items center
    +media(sp)
      width 100%
      flex-direction column
    &:nth-of-type(1)
      +media(pc)
        order 2
        margin-top 20px
    &:nth-of-type(2)
      +media(pc)
        order 1
      +media(sp)
        padding 0 30px


  // LIST MENU 1、2
  .list-menu-1
  .list-menu-2
    display flex
    align-items center
    letterspacing(80)
    a
      hover-opacity()
    >li
      >a
        +media(pc)
          padding 6px

  // LIST MENU 2
  .list-menu-2
    +media(pc)
      margin-right 11px
      font-rem(12)
      li
        margin-left 16px
    +media(sp)
      justify-content center
      margin 36px -30px 0
      font-rem(12,,100)
      li
        margin 0 27px
        a
          padding 14px 0

  // LIST MENU 1
  .list-menu-1
    +media(sp)
      flex-direction column
      border-top 1px solid #c40914
    >li
      +media(pc)
        margin-left 24px
        font-rem(14)
        > a
          .btn-icon
            display none
        &:last-child
          > a
            padding-right 0
        &:hover
          .list-menu-overlay
            opacity .7
          .list-menu-sub
            opacity 1
            pointer-events auto
            + a
              &::before
                opacity 1
      +media(sp)
        display flex
        flex-direction column
        width 100%
        border-bottom 1px solid #c40914
        >a
          position relative
          display flex
          align-items center
          order 1
          lineheight(14,22)
          min-height 60px
          padding 10px 50px 10px 30px
          box-sizing border-box
          .btn-icon
            absolute top 50% right 30px
            display block
            size 14
            margin-top -6px
            margin-left 14px
            transform rotate(-90deg)
            fill #fff
        .list-menu-sub
          display none
          order 2
        &.is-shown
          >a
            .btn-icon
              &::after
                display none !important
          .list-menu-sub
            display block
      .list-menu-sub
        + a
          position relative
          +media(pc)
            &:hover
              opacity 1
              color #febec2
            &::before
              absolute bottom -36px left 50%
              display block
              opacity 0
              content ""
              margin-left -12px
              size 0
              border-style solid
              border-width 0 12px 12px 12px
              border-color transparent transparent #fff transparent
              z-index 2
              transition .3s
          +media(sp)
            pointer-events none
            .btn-icon
              absolute top 50% right 33px
              size 14
              margin-top -7px
              &::before
              &::after
                absolute top 50% left 50%
                content ""
                display block
                background #fff
              &::before
                size 2 13
                margin-top -6px
              &::after
                size 13 2
                margin-left -6px
              svg
                display none

  // サブメニュー
  .list-menu-overlay
    fixed top left
    content ""
    display block
    size 100%
    background $color-red
    opacity 0
    z-index 1
    pointer-events none
    transition .3s
    +media(sp)
      display none
  .list-menu-sub
    display none
    width 100%
    box-sizing border-box
    +media(pc)
      absolute top 82px left
      display block
      padding 36px 40px 0
      pointer-events none
      transition .3s
      z-index 2
      opacity 0
      &:hover
        opacity 1
    .inner
      +media(pc)
        z-index 2
        background #fff
        border-radius 5px
        position relative
    .contents
      display flex
      justify-content space-between
      margin 0 auto
      +media(pc)
        align-items flex-start
        width ($size-pc-contents)px
        padding-top 51px
        padding-bottom 60px
      a
        +media(pc)
          color #000
        +media(sp)
          color #fff
    .category
      line-height 1
      padding-top 18px
      .en
        display block
        color $color-red
        font-rem(40,,2)
      .btn
        position relative
        display inline-flex
        align-items center
        margin-top 3px
        padding 10px 0
        .btn-text
          font-rem(13,,100)
        .btn-icon
          display block
          size 14
          margin-left 14px
          transform rotate(-90deg)
          +media(pc)
            fill $color-red
          +media(sp)
            fill #fff
      +media(pc)
        width 240px
      +media(sp)
        display none
    ul
      display flex
      flex-wrap wrap
      flex 1
      li
        +media(pc)
          width 200px
          margin-left 40px
          border-bottom 1px solid #ccc
        +media(sp)
          width 100%
          border-top 1px solid #c40914
        a
          position relative
          display flex
          align-items center
          justify-content space-between
          size 100%
          box-sizing border-box
          +media(pc)
            padding 10px 20px 10px 0
            min-height 60px
            lineheight(14,22)
            box-sizing border-box
          +media(sp)
            display flex
            align-items center
            padding 10px 60px 10px 50px
            min-height 60px
            lineheight(14,22)
            background #e81824
          .btn-icon
            absolute top 50% 
            display block
            size 14 8
            transform rotate(-90deg)
            +media(pc)
              right -4px
              margin-top -4px
              fill $color-red
            +media(sp)
              right 33px
              margin-top -4px
              fill #fff

  // LANGUAGE
  .list-language
    display flex
    align-items center
    +media(pc)
      font-rem(12)
      margin-right 20px
    +media(sp)
      flex-direction row
      justify-content center
      margin-top 21px
      font-rem(16,,100)
    li
      a
        +media(pc)
          padding 10px
        +media(sp)
          padding 12px 16px 10px
      &.located
        a
          color #111
          pointer-events none
      &:first-child
        position relative
        +media(pc)
          margin-right 6px
        +media(sp)
          margin-right 6px
        &::after
          content ""
          display block
          background #fff
          +media(pc)
            absolute top 50% right -4px
            size 1 9
            margin-top -4px
          +media(sp)
            absolute top 50% right -4px
            size 1 13
            margin-top -4px
  
  // 検索ボタン
  .btn-search
    position relative
    padding 8px 10px
    margin-right 30px
    hover-opacity()
    +media(pc)
      z-index 1
    +media(sp)
      display none
    .icon
      fill #fff
      size 15

  // 検索フィールド
  .field-search
    width 100%
    background-color rgba($color-red, .9)
    z-index 3
    box-sizing border-box
    +media(pc)
      display none
      fixed top left
      height 150px
    +media(sp)
      margin-top 39px
      height 60px
    &.is-opened
      display block
    .inner
      position relative
      margin 0 auto
      height 100%
      box-sizing border-box
      +media(pc)
        width ($size-pc-contents)px
        padding-top 45px
      +media(sp)
        width 100%
    .field
      position relative
      margin 0 auto
      background #bd0d17
      border-radius 4px
      box-sizing border-box
      +media(pc)
        size 800 60
        font-rem(14)
      +media(sp)
        size 100%
        font-rem(13)
      ::-webkit-input-placeholder
        color #fff
        font-rem(14,,130)
        opacity .4
        font-weight bold
      ::-webkit-search-cancel-button
        -webkit-appearance none
      .searchform
        size 100%
      input[type="search"]
        margin 0
        padding 0
        color #fff
        background none
        border none
        outline none
        appearance none
        border-radius 0
        box-sizing border-box
        size 100%
        font-rem(14)
        +media(pc)
          padding 0 50px 0 28px
        +media(sp)
          padding 0 50px 1px 17px
    .icon-search
      absolute top 50%
      fill #fff
      +media(pc)
        right 29px
        size 18
        margin-top -9px
        cursor pointer
      +media(sp)
        right 16px
        size 15
        margin-top -7px
    .btn-close
      absolute top 50% right
      size 26
      margin-top -13px
      fill #fff
      hover-opacity()
      +media(sp)
        display none

  // ログインボタン
  .btn-login
    btn-square()
    +media(pc)
      size 110 36
      font-rem(12)
    +media(sp)
      size 100% 60
      margin-top 15px
      font-rem(13)