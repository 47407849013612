use('../plugins/has-own-property.js')

//サイズ指定
size($width, $height = $width)
  width _add-pixel($width)
  height _add-pixel($height)

vw-pc($val)
  _vw($val, $size-pc)

vw-sp($val)
  _vw($val, $size-sp)

media($device)
  unless $breakpoint[$device]
    error($device + 'is not defined.')
  @media $breakpoint[$device]
    {block}

// フォントサイズ設定
font-size-setting-pc(font-size, line-height-size = null, font-type = null)
  font-size (font-size)px
  font-size ((font-size) * 0.1)rem
  if line-height-size
    line-height (line-height-size / font-size)
  if font-type is vw
    @media only screen and (min-width: (media-pc)px) and (max-width: (size-pc + 1)px)
      font-size (font-size / (size-pc + 1) * 100)vw
  if font-type is percent
    font-size ((font-size / 16) * 100)%

font-size-setting-sp(font-size, line-height-size = null, font-type = null)
  font-size (font-size / 2)px
  font-size ((font-size / 2) * 0.1)rem
  if line-height-size
    line-height (line-height-size / font-size)
  if font-type is vw
    font-size (font-size / size-sp * 100)vw
  if font-type is percent
    font-size ((font-size / 16) * 100)%

//パーセントのサイズを割り出す
get-percent-size(size, base-size)
  (size / base-size * 100)%
//コンテンツを上下左右センタリング
//@example
//----------
//centering()  // translate
//centering(500px)  // margin
//centering(500px, 200px)  // margin
// ----------
centering($width = null, $height = $width)
  absolute top 50% left 50%
  if $width
    margin ($height / -2)px 0 0 ($width / -2)px
    size($width, $height)
  else
    transform translateX(-50%) translateY(-50%)

//コンテンツをセンタリング
container-centering($width, $base-width = null)
  position relative
  if $base-width
    width _percentage($width, $base-width)
  else
    width _add-pixel($width)
  margin-right auto
  margin-left auto

//スプライト画像
//@example
//----------
//sprites($sprite-path + 'button/logo.png')  // hover 無し
//sprites($sprite-path + 'button/logo', '-hover', '.png')  // hover 有り
//sprites($sprite-path + 'button/logo', '-hover', '.png', '&.is-located')  // hover & selector 有り
//----------
sprites($path, $suffix = false, $extension = false, $selector = false)
  if !$suffix and !$extension
    sprite $path
  if $suffix
    sprite $path + $extension
    if $selector
      &:hover, {$selector}
        sprite $path + $suffix + $extension
    else
      &:hover
        sprite $path + $suffix + $extension

//スプライト画像のホバーアニメーション（フェード）
//@example
//----------
//sprite-fade-hover($sprite-path + 'sample/a')
//sprite-fade-hover($sprite-path + 'sample/a', {
//  duration: .5, crossFade: true, suffixBefore: '_off', suffixAfter: '_on'
//})
//----------
sprite-fade-hover($image, $opts = {})
  $default-opts = {
    duration    : .2
    crossFade   : false
    suffixBefore: '-off'
    suffixAfter : '-on'
  }
  $opts = merge($default-opts, $opts)
  $image-size = _get-img-size($image + $opts.suffixBefore + '.png')
  size $image-size.width $image-size.height
  position relative
  animation: delayView ($opts.duration)s linear
  &::before, &::after
    content ''
    display block
    absolute top 0 left 0
  &::before
    sprite($image + $opts.suffixBefore + '.png')
    if opts.crossFade
      animation: fadeIn ($opts.duration)s linear
  &::after
    opacity 0
    sprite(image + $opts.suffixAfter + '.png')
    animation: fadeOut ($opts.duration)s linear
  &:hover
    if $opts.crossFade
      &::before
        opacity 0
        animation: fadeOut ($opts.duration)s linear
    &::after
      opacity 1
      animation: fadeIn ($opts.duration)s linear

lineheight($font-size = null, $line-height-size = $font-size)
 line-height ($line-height-size / $font-size)

letterspacing($letter-spacing-size)
 letter-spacing ($letter-spacing-size / 1000) em

font-rem($font-size, $line-height-size = null, $letter-spacing-size = null)
 font-size ($font-size / 10) rem
 if $line-height-size
   lineheight($font-size, $line-height-size)
 if $letter-spacing-size
   letterspacing($letter-spacing-size)

font-pixel($font-size, $line-height-size = null, $letter-spacing-size = null)
 font-size $font-size px
 if $line-height-size
   lineheight($font-size, $line-height-size)
 if $letter-spacing-size
   letterspacing($letter-spacing-size)

font-percent($font-size, $line-height-size = null, $letter-spacing-size = null)
 font-size ($font-size / 16 * 100) %
 if $line-height-size
   lineheight($font-size, $line-height-size)
 if $letter-spacing-size
   letterspacing($letter-spacing-size)

font-vw($font-size, $line-height-size = null, $letter-spacing-size = null)
 // font-size -vw($font-size, $size-sp)
 font-size ($font-size / $size-sp * 100) vw
 if $line-height-size
   lineheight($font-size, $line-height-size)
 if $letter-spacing-size
   letterspacing($letter-spacing-size)

//10pxより小さいfont-size指定（chrome対策）
small-text($size)
  display inline-block
  transform scale($size)
  transform-origin 0 0

//文字置換
text-replace()
  text-indent 100%
  text-decoration none
  white-space nowrap
  overflow hidden

//2行目以降を一文字下げる
second-line-indent($width)
  padding-left $width
  text-indent -1 * $width

//背景画像指定
//@example
//----------
//bg-img($image-path + "aaa.jpg", left, top, no-repeat, #fff)
//----------
bg-img($file-name, $position-x = left, $position-y = top, $repeat-type = null, $bg-color = null, $bg-size = false)
  background url($file-name) $position-x $position-y $repeat-type $bg-color
  if $bg-size
    background-size $bg-size

//背景画像指定（複数）
//@example
//----------
//bg-img-multiple({
//  '1': $image-path + "aaa.jpg" (left top) no-repeat
//  '2': $image-path + "bbb.jpg" (left top) no-repeat #fff
//}, {
//  '1': (50px 100px)
//  '2': (50px 100px)
//})
//----------
bg-img-multiple($imagesHash, $sizesHash = false)
  $images = null
  for i, props in $imagesHash
    for prop, j in props
      if j is 0
        if i is 1
          $images = $images url(prop)
        else
          $images = $images, url(prop)
      else
        if prop
          $images = $images prop
  background $images
  if $sizesHash
    $sizes = null
    for $i, $prop in $sizesHash
      if $prop
        if $i is 1
          $sizes = $sizes $prop
        else
          $sizes = $sizes, $prop
    background-size $sizes

//アスペクト比を固定したままリサイズ（IE8以上）
//@example
//----------
//aspect-ratio-kept-container('../../htdocs/images/sample.png')  // image
//aspect-ratio-kept-container('images/sample/a.png')  // sprite
//aspect-ratio-kept-container(500, 300)  // size
// ----------
aspect-ratio-kept-container($width-or-imgpath, $height = null)
  if typeof($width-or-imgpath) is 'string'
    $img-size = _get-img-size($width-or-imgpath)
    $width  = $img-size.width
    $height = $img-size.height
  else
    $width = $width-or-imgpath
  width 100%
  position relative
  &::before
    content ""
    display block
    padding-top ($height / $width * 100)%
  > *
    position absolute

font-roboto()
  font-family 'Roboto', sans-serif
  font-weight 500

btn-square($background = #000,$border-radius = 5,$color = null)
  position relative
  display flex
  flex-direction column
  justify-content center
  align-items center
  border-radius ($border-radius)px
  background $background
  box-sizing border-box
  if $color
    color $color
  span
    display block
  +media(pc)
    transition .3s
    overflow hidden
    &::before
    &::after
      content ""
      display block
      box-sizing border-box
    &::before
      absolute top right bottom left
      background #fff
      size 0 100%
      transform skew(-50deg)
      transition-duration 0.6s
      transform-origin top left
    &::after
      absolute top left
      size 100%
      border 1px solid $background
      border-radius 5px
    .btn-text
    .btn-icon
      z-index 1
      transition .3s
    &:hover
      &::before
        width 145%
      .btn-text
        color $background
      .btn-icon
        fill $background

hover-opacity($opacity = .7)
  +media(pc)
    transition .3s
    &:hover
      opacity $opacity

text-line-1()
  text-overflow ellipsis
  overflow hidden
  white-space nowrap