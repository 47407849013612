//- ---------------------------------------------------------------
//- FOOTER
//- ---------------------------------------------------------------
.footer
  .footer-inner
    display flex
    align-items center
    flex-wrap wrap
    margin 0 auto
    line-height 1
    +media(pc)
      max-width ($size-pc-contents)px
      height 90px
    +media(sp)
      padding-top 16px
  .logo
    a
      display block
    +media(sp)
      width (156/2)px
      margin-left 30px
  .list-share
    display flex
    align-items center
    margin-top 2px
    +media(pc)
      margin-left 26px
    +media(sp)
      margin-right 20px
      margin-left auto
    a
      display flex
      align-items center
      justify-content center
      size 34
      hover-opacity()
    .btn-fb
      .icon
        size 9 17
    .btn-tw
      margin-left 6px
      .icon
        size 19 18
    .btn-youtube
      margin-left 12px
      .icon
        size 29 20
  .copyright
    +media(pc)
      margin-left auto
      margin-bottom 2px
      margin-right -2px
      font-rem(11)
    +media(sp)
      width 100%
      margin-top 16px
      padding 15px 0 14px 40px
      text-align center
      font-rem(10,,40)
      border-top 1px solid #e6e6e6
      box-sizing border-box
      span
        small-text(.8)
  .privacy-policy
  .privacy-policy-sp
    a
      color #000
  .privacy-policy
    +media(pc)
      margin 0 auto 0 20px
      font-rem(11)
    +media(sp)
      display none
  .privacy-policy-sp
    +media(pc)
      display none
    +media(sp)
      padding 2px 0 0 0
      margin 0 auto 0 35px
      text-align center
      font-rem(10,,40)
      box-sizing border-box