//- ---------------------------------------------------------------
//- 入会申請、お問い合わせ
//- ---------------------------------------------------------------
.page-form

  .page-contents
    +media(pc)
      padding-top 160px
      padding-bottom 160px
    +media(sp)
      padding-top (130/2)px
      padding-bottom (200/2)px

  .list-flow
    display flex
    justify-content space-between
    text-align center
    li
      line-height 1
      +media(pc)
        width 310px
        font-rem(15)
      +media(sp)
        width _percentage(200, 630)
        font-rem(10)
      span
        display block
      .line
        width 100%
        background #dcdcdc
        +media(pc)
          height 8px
        +media(sp)
          height 5px
      .text
        +media(pc)
          margin-top 35px
        +media(sp)
          margin-top 20px
      &.located
        .line
          background $color-red
        .text
          color $color-red
  
  .text-container
    li
      position relative
      .asterisk
        absolute top
      .text-small
        font-rem(12)
      a
        hover-opacity()
        color $color-red
    +media(pc)
      margin-top 90px
      letterspacing(50)
      li
        .asterisk
          left -10px
        .text-small
          margin-left 10px
    +media(sp)
      margin-top 44px
      letterspacing(26)
      text-align justify
      li
        .asterisk
          left -8px
        .text-small
          display block
          margin-top -2px

  .mw_wp_form .horizontal-item + .horizontal-item
    margin-left 0

.page-form
.page-article-event
  .form-container
    ::-webkit-input-placeholder
      color #999999
    +media(pc)
      margin-top 71px
    +media(sp)
      margin-top 41px
    input, button, textarea, select
      margin 0
      padding 0
      color #000
      background none
      border none
      outline none
      appearance none
      border-radius 0
      box-sizing border-box
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="url"],
    input[type="password"]
    textarea
      width 100%
      font-family $font-family-base
      letterspacing(120)
      border 1px solid #dcdcdc
      background #f6f6f6
      +media(pc)
        padding 19px 25px
        font-rem(15,30)
      +media(ie11)
        padding-top 17px
        padding-bottom 21px
      +media(sp)
        padding 17px 14px 14px
        font-rem(12,22)
    input[type="button"],
    input[type="submit"]
      size 100%
      font-weight bold
      cursor pointer
      +media(pc)
        font-rem(16)
      +media(sp)
        font-rem(13)
  .form-contents
    border-top 1px solid #dcdcdc
    border-bottom 1px solid #dcdcdc
    +media(pc)
      padding-top 80px
      padding-bottom 8px
    +media(sp)
      padding-top 46px
      padding-bottom 0
  .item
    display flex
    box-sizing border-box
    +media(pc)
      margin-bottom 40px
      font-rem(15,30,120)
    +media(sp)
      flex-direction column
      margin-bottom (64/2)px
      font-rem(14,25,60)
  .item-title
    display flex
    flex-direction column
    justify-content center
    font-weight bold
    box-sizing border-box
    +media(pc)
      width (240)px
    > span
      display flex
      align-items center
    .icon-required
      color #fff
      text-align center
      font-weight bold
      background #fb2834
      box-sizing border-box
      +media(pc)
        size 42 21
        margin-left 12px
        padding-top 5px
        font-rem(10,10)
        border-radius 10px
      +media(ie11)
        padding-top 6px
      +media(sp)
        size 30 15
        margin-left 8px
        padding-top 3px
        font-rem(10,10)
        border-radius 7px
        span
          small-text(.8)
          margin-left 4px
    .sub
      letterspacing(70)
      font-weight normal
      font-family $font-notosansjp-yakuhan
      +media(pc)
        padding-bottom 4px
        font-rem(12,16)
      +media(sp)
        padding-bottom 6px
        font-rem(10,10)
    .link-text
      position relative
      align-self flex-start
      color $color-red
      box-sizing border-box
      hover-opacity()
      +media(pc)
        padding 10px 18px 10px 0
        font-rem(12,16)
      +media(sp)
        padding 8px 14px 8px 0
        font-rem(10,10)
      .icon
        absolute top 50% right
        display block
        transform rotate(-90deg)
        fill $color-red
        +media(pc)
          size 12
          margin-top -6px
          margin-left 14px
        +media(sp)
          size 10
          margin-top -5px
          margin-left 14px
  .item-contents
    position relative
    flex 1
    +media(sp)
      margin-top 12px
    .text-error
      display none
      absolute bottom -20px left
      color #fb2834
      font-rem(10)
  .item
    &.is-error
      .item-contents
        .text-error
          display block

  // 横並び
  .item-side
    .item-contents
      display flex
      +media(sp)
        justify-content space-between
      input
        +media(pc)
          width 200px
          margin-right 20px
        +media(sp)
          width calc(50% - 7px)

  // 従業員数
  .item-employees
    .item-contents
      input
        +media(pc)
          width 420px

  // メール
  .item-mail
    .item-title
      letterspacing(20)

  // ラジオボタン
  .item-radio
    +media(pc)
      margin-top 61px
    +media(sp)
      margin-top 29px
    .item-title
      justify-content flex-start
    .item-contents
      +media(sp)
        margin-top 29px
      display flex
      flex-wrap wrap
      +media(sp)
        flex-direction column
      .mwform-radio-field
        display flex
        +media(pc)
          width 240px
          margin-bottom 30px
          font-rem(15)
        +media(sp)
          margin-bottom 17px
          font-rem(13)
      input
        display none
        & + span
          position relative
          cursor pointer
          +media(pc)
            padding-left 38px
          +media(sp)
            padding-left 30px
          &::before
            absolute left
            display block
            content ""
            border 1px solid #aaa
            border-radius 50%
            box-sizing border-box
            +media(pc)
              top -2px
              size 26
            +media(sp)
              top 1px
              size 20
        &:checked
          & + span
            &::after
              display block
              content ""
              border-radius 50%
              background #aaa
              +media(pc)
                absolute top 3px left 5px
                size 16
              +media(sp)
                absolute top 6px left 5px
                size 10
      .text-error
        +media(pc)
          bottom -10px
        +media(sp)
          bottom -15px

  .btn-container
    display flex
    +media(pc)
      margin-top 100px
      justify-content center
    +media(sp)
      margin-top 50px
      justify-content space-between
    div
      btn-square()
      size 100%
      letterspacing(120)
      input
      a
        color #fff
      +media(pc)
        size 320 100
        margin 0 15px
        font-rem(16)
      +media(sp)
        width calc(50% - 7px)
        height 70px
        font-rem(13)
      &:hover
        input
        a
          z-index 1
          display block
          color #000

    // 戻るボタン
    .btn-back
      background #808080
      &::after
        border-color #808080

    // トップに戻るボタン
    .btn-back-to-top
      +media(pc)
        size 480 100
      +media(sp)
        size 100% 70
        margin 0

  // 添付ファイル
  .item-file
    .item-contents
      .text-error
        +media(pc)
          bottom -3px

//- 入力ページ
//- ---------------------------------------------------------------
.page-form-input
  // テキストエリア
  .item-textarea
    .item-title
      justify-content flex-start
      +media(pc)
        padding-top 20px
    .item-contents
      lineheight(1)
      font-size 0
      textarea
        +media(pc)
          height 300px
        +media(sp)
          height 200px
  // 確認ボタン
  .btn-container
    div
      &.btn-send
        +media(pc)
          size 480 100
        +media(sp)
          size 100% 70
          margin 0
      // 戻るボタン
      &.btn-back
        display none

//- 確認ページ
//- ---------------------------------------------------------------
.page-form-confirm
  .form-container
    +media(pc)
      margin-top 103px
    +media(sp)
      margin-top 42px
  .form-contents
    +media(pc)
      padding-top 61px
      padding-bottom 0
    +media(sp)
      padding-top 8px
      padding-bottom 0
  .item
    margin-bottom 0
    border-bottom 1px solid #dcdcdc
    +media(pc)
      padding-top 39px
      padding-bottom 40px
    +media(sp)
      padding-top 38px
      padding-bottom 37px
  .item-contents
    +media(sp)
      margin-top 20px
  .item-radio
    +media(pc)
      margin-top 0
    +media(sp)
      margin-top 0
    .item-contents
      +media(sp)
        margin-top 20px

//- 完了ページ
//- ---------------------------------------------------------------
.page-form-complete
  .page-contents
    +media(pc)
      padding-bottom 72px
    +media(sp)
      padding-bottom 100px
  .text-container
    border-bottom 1px solid #dcdcdc
    +media(pc)
      margin-top 93px
      padding-bottom 151px
    +media(sp)
      margin-top 70px
      padding-bottom 74px
    p
      &:not(:last-child)
        +media(pc)
          margin-bottom 34px
        +media(sp)
          margin-bottom 26px
      &.text-large
        font-weight bold
        +media(pc)
          font-rem(22,32)
        +media(sp)
          margin-bottom 30px
          font-rem(21,27)
    a
      hover-opacity()
      color $color-red