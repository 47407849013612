//- ---------------------------------------------------------------
//- 記事一覧
//- ---------------------------------------------------------------

//- 記事一覧 カテゴリタイトル
//- ---------------------------------------------------------------
.category-title
  font-weight bold
  +media(pc)
    padding-top 52px
    padding-bottom 50px
    font-rem(24,36,50)
  +media(sp)
    padding-top 43px
    padding-bottom 42px
    font-rem(21,36,25)

//- 記事リスト
//- ---------------------------------------------------------------
.list-article
  border-top 1px solid #dcdcdc
  li
    border-bottom 1px solid #dcdcdc
    a
      display flex
      align-items flex-start
      color #000
      hover-opacity()
      +media(pc)
        padding 40px 0 46px
      +media(sp)
        padding 26px 0 24px
    .thumbnail
      +media(pc)
        width 220px
        margin-right 70px
      +media(sp)
        width 110px
        margin-right 25px
      img
        border-radius 6px
        overflow hidden
        width 100%
      &.frame-border
        position relative
        &::after
          absolute top left
          content ""
          display block
          size 100%
          border 1px solid #dcdcdc
          box-sizing border-box
          +media(pc)
            border-radius 6px
          +media(sp)
            border-radius 5px
    .contents
      flex 1
      text-align justify
      box-sizing border-box
      overflow hidden
      .date
        line-height 1
        +media(pc)
          margin-top 3px
          font-rem(12,,50)
        +media(sp)
          font-rem(10,,50)
          small-text(.9)
          display block
      .title
        color $color-red
        font-weight bold
        +media(pc)
          margin-top 12px
          font-rem(22,32,25)
          text-line-1()
        +media(sp)
          margin-top 10px
          font-rem(14,18,25)
      .text
        +media(pc)
          margin-top 17px
          font-rem(13,26,25)
        +media(sp)
          display none

//- ICON SORT
//- ---------------------------------------------------------------
.btn-icon-sort
  display block
  +media(pc)
    size 10 12
    margin-left 9px
  +media(sp)
    size 6 9
    margin-left 7px
  span
    display block
    fill #bebebe
    +media(pc)
      size 9 6
    +media(sp)
      size 6 4
    &:nth-child(1)
      transform rotate(180deg)
      +media(pc)
        margin-top -1px
      +media(sp)
        margin-top 0px
    &:nth-child(2)
      +media(pc)
        margin-top 2px
      +media(sp)
        margin-top 3px

//- SUB NAV
//- ---------------------------------------------------------------
.sub-nav
  display none
  absolute left
  z-index 1
  box-sizing border-box
  +media(pc)
    top 20px
    width 264px
    &:hover
      display block
  +media(sp)
    top 73px
    width 100%
    padding 0 30px
  .inner
    position relative
    border 1px solid #d6d6d6
    border-radius 5px
    background #fff
    box-sizing border-box
    +media(sp)
      padding-top 6px
  ul
    +media(sp)
      margin-bottom 13px
  li
    +media(pc)
      font-rem(14,24)
    +media(sp)
      font-rem(12,24)
    a
      display block
      color #000
      +media(pc)
        padding 8px 28px
        &:hover
          color #fff
          background #ff0000
      +media(sp)
        padding 8px 20px
  .btn-close
    +media(pc)
      display none
    +media(sp)
      display flex
      justify-content center
      align-items center
      width 100%
      padding 11px 0
      background #fafafa
      border-radius 0 0 5px 5px
      .btn-icon
        display block
        size 10
        margin-right 10px

//- 年代ボタン
//- ---------------------------------------------------------------
.btn-age
  position relative
  margin-left auto
  margin-right 0
  background #fff
  box-sizing border-box
  cursor pointer
  +media(pc)
    absolute top 50px right
    size 150 36
    margin-top 2px
  +media(sp)
    size 100% 40
    margin 20px 0 0
    box-sizing border-box
  .btn-select
    position relative
    display flex
    align-items center
    size 100%
    border 1px solid #d6d6d6
    border-radius 5px
    color #000
    box-sizing border-box
    +media(pc)
      padding-left 25px
      font-rem(13)
    +media(sp)
      font-rem(13)
      justify-content center
    .btn-icon-sort
      absolute top 50% right 20px
      +media(pc)
        margin-top -6px
      +media(sp)
        margin-top -4px
  .sub-nav
    width 100%
    +media(pc)
      top 35px
    +media(sp)
      top 39px
      padding 0
    ul
      li
        font-rem(13,13)
        a
          padding 11px 28px 12px
  +media(pc)
    &:hover
      .btn-select
        border-bottom none
        border-radius 5px 5px 0 0
        .btn-icon-sort
          margin-top -7px
      .sub-nav
        display block
        .inner
          border-top none
          padding-top 1px
          border-radius 0 0 5px 5px
  +media(sp)
    &.is-opened
      .btn-select
        border-bottom none
        border-radius 5px 5px 0 0
      .sub-nav
        display block
        .inner
          border-top none
          padding-top 1px
          border-radius 0 0 5px 5px

//- CATEGORY NAV
//- ---------------------------------------------------------------
.category-nav
  position relative
  margin 0 auto
  display flex
  align-items center
  line-height 1
  letterspacing(50)
  box-sizing border-box
  +media(pc)
    width ($size-pc-contents)px
    padding 60px 0 60px
    border-bottom 1px solid #dcdcdc
  +media(sp)
    flex-wrap wrap
  .title
    padding-right 31px
    font-rem(18)
    font-weight bold
    +media(sp)
      display none
  > ul
    display flex
    align-items center
    +media(sp)
      width 100%
      justify-content center
      box-sizing border-box
      border-bottom 1px solid #e2e2e2
    >li
      +media(pc)
        position relative
      >a
        position relative
        display flex
        align-items center
        color #000
        hover-opacity()
        +media(pc)
          padding 2px 29px 2px 30px
          &::before
            absolute top left
            content ""
            display block
            size 1 20px
            background #e2e2e2
        +media(sp)
          padding 25px 15px 23px 15px
          &::before
            display none
            absolute bottom -18px left 32px
            content ""
            size 8px
            border 1px solid #e2e2e2
            background #fff
            border-bottom none
            border-right none
            transform rotate(45deg)
            z-index 2
      .sub-nav
        + a
          // pointer-events none
        +media(pc)
          padding-top 16px
      +media(pc)
        cursor pointer
        &:hover
          .sub-nav
            display block
      +media(sp)
        &.is-opened
          >a
            &::before
              display block
          .sub-nav
            display block
      .btn-text
        +media(pc)
          font-rem(16)
        +media(sp)
          font-rem(12)
      &.is-located
        >a
          color $color-red
          +media(sp)
            padding-right 8px
  .btn-age
    +media(pc)
      relative top left
    +media(sp)
      margin-left 30px
      margin-right 30px

//- 記事一覧 pager
//- ---------------------------------------------------------------
ul.page-numbers
  display flex
  justify-content center
  +media(pc)
    margin-top 60px
    margin-bottom 160px
    margin-right 2px
  +media(sp)
    margin-top 50px
    margin-bottom 100px
    margin-right 2px
  li
    margin 0 3px
    +media(pc)
      size 40
    +media(sp)
      size 35
    .page-numbers
      display flex
      align-items center
      justify-content center
      size 100%
      color #000
      font-weight bold
      line-height 1
      border-radius 50%
      hover-opacity()
      +media(pc)
        font-rem(15)
      +media(sp)
        font-rem(13)
      &.current
        color #fff
        background $color-red
        &:hover
          opacity 1
      &.next
        position relative
        color #fff
        background #fff
        border 1px solid #ddd
        +media(pc)
          margin-left 3px
        +media(sp)
          margin-left 3px
        &::before
          absolute top 50% left 50%
          transform rotate(-90deg)
          size 14 8
          display block
          content ""
          bg-img($image-path + "icon-arrow.svg")
          background-size cover
          margin-top -4px
          +media(pc)
            size 12 7
            margin-left -5px
          +media(sp)
            size 10 6
            margin-left -7px

//- ---------------------------------------------------------------
//- 記事詳細
//- ---------------------------------------------------------------

.page-article
  .page-container
    +media(pc)
      padding-bottom 160px
  &.page-article-event
    .page-container
      +media(pc)
        padding-bottom 160px
      +media(sp)
        padding-bottom 100px

//- 記事詳細 タイトル
//- ---------------------------------------------------------------
.page-article
  .page-heading
    font-weight bold
    +media(pc)
      height auto
      padding-top 224px
      padding-bottom 73px
    +media(sp)
      height auto
      padding-top 99px
      padding-bottom 42px
    .data
      display flex
      align-items center
    .tag
      display inline-block
      background #fff
      line-height 1
      +media(pc)
        margin-left 17px
        padding 7px 10px 7px 14px
        font-rem(12)
        border-radius 13px
      +media(sp)
        margin-left 10px
        padding 5px 0px 3px 10px
        font-rem(10)
        border-radius 9px
        vertical-align top
        margin-top -4px
      span
        color $color-red
        +media(sp)
          small-text(.8)
    +media(pc)
      .data
        font-rem(15,,60)
      .title
        margin-top 16px
        font-rem(38,56,25)
    +media(sp)
      padding-left 28px
      padding-right 28px
      .data
        font-rem((20/2),,60)
        small-text(.9)
      .title
        margin-top 10px
        font-rem((42/2),(72/2),25)
  &.page-article-event
    .page-heading
      +media(pc)
        padding-top 218px
      .title
        +media(pc)
          margin-top 12px


//- 記事詳細 コンテンツ
//- ---------------------------------------------------------------
.article-contents
  margin 0 auto
  letterspacing(50)
  text-align justify
  +media(pc)
    width 720px
    padding-top 91px
    padding-bottom 100px
  +media(sp)
    padding-top 58px
    padding-bottom 75px
  blockquote
    position relative
    background rgba(0,0,0,.05)
    padding 15px 15px 15px 70px
    +media(pc)
      margin-bottom 32px
    +media(sp)
      margin-bottom 27px
    &::before
      absolute left 20px top 20px
      content "\f10d"
      display block
      color rgba(0,0,0,.1)
      font-size 32px
      font-family FontAwesome
      line-height normal
  .mk-video-container
    position relative
    padding-bottom 56.25%
    height 100%
    overflow hidden
    iframe
      absolute top left
      size 100%
  .alignleft
    text-align left
  .alignright
    text-align right
  .aligncenter
    text-align center
  strong
    font-weight bold
  h2
    font-size 30px
    font-weight bold
  p
    +media(pc)
      margin-bottom 32px
    +media(sp)
      margin-bottom 27px
  img
    height auto
    +media(pc)
      max-width 720px
    //   margin-bottom 19px
    //   &:not(:first-child)
    //     margin-top 19px
    // +media(sp)
    //   margin-bottom 16px
    //   &:not(:first-child)
    //     margin-top 16px
  a
    display inline-block
    color $color-red
    &.link-blank
      position relative
      display inline-block
      hover-opacity()
      word-break break-all
      +media(pc)
        padding-right 23px
      +media(sp)
        padding-right 18px
      &::after
        absolute top 50% right
        display block
        content ""
        bg-img($image-path + "activity/icon-blank.svg")
        +media(pc)
          size 14 12
          margin-top -5px
        +media(sp)
          size 11 9
          margin-top -3px
    &.link-pdf
      display inline-flex
      align-items center 
      img
        width 80px
        margin-bottom 0
  .btn-square
    +media(sp)
      text-align center
    a
      btn-square(,,#fff)
      display inline-flex
      +media(pc)
        height 60px
        margin-top 9px
        padding 0 65px 3px 44px
      +media(sp)
        height 50px
        margin 0 auto
        margin-top 16px
        padding 0 61px 3px 56px
      .btn-icon
        display block
        content ""
        size 10
        bg-img($image-path + "activity/icon-arrow.svg")
      .btn-text
        line-height 1
        +media(pc)
          font-rem(14,,50)
        +media(sp)
          font-rem(13)
      .btn-icon
        absolute top 50%
        fill #fff
        transform translateY(-50%)
        +media(pc)
          right 20px
          size 12
        +media(sp)
          right (30/2)px
          size 8
      &:hover
        .btn-icon
          bg-img($image-path + "activity/icon-arrow-hover.svg")
  .logo-flex-block
    +media(pc)
      display flex
      align-items flex-start
      margin-bottom 20px
      img
        margin-bottom 0
        margin-right 40px
      .text-large
        font-rem(30,50)
    +media(sp)
      margin-bottom 20px
      img
        display block
        width 120px
        margin 0 auto 20px
      .text-large
        font-rem(20,40)
  .flex-block
    +media(pc)
      display flex
      align-items flex-start
      .block-right
        padding-left 30px

//- 記事詳細 pager
//- ---------------------------------------------------------------
.pager-article
  position relative
  margin 0 auto
  border-top 1px solid #dcdcdc
  border-bottom 1px solid #dcdcdc
  box-sizing border-box
  +media(pc)
    size 960 142
  +media(sp)
    height 88px
  a
    display flex
    align-items center
    hover-opacity()
  .btn-text
    color #000
    font-weight bold
    +media(pc)
      font-rem(15)
    +media(sp)
      font-rem(12)
  .btn-icon
    position relative
    size 40
    background #fff
    border-radius 50%
    border 1px solid #ddd
    box-sizing border-box
    svg
      absolute top 50% left 50%
      size 14 8
      margin-top -4px
  .btn-prev
  .btn-next
    absolute top 50%
    margin-top -20px
  .btn-prev
    +media(pc)
      left 0
    +media(sp)
      left 30px
    .btn-text
      +media(pc)
        margin-left 15px
      +media(sp)
        margin-left 12px
    .btn-icon
      svg
        transform rotate(90deg)
        margin-left -6px
  .btn-next
    +media(pc)
      right 0
    +media(sp)
      right 30px
    .btn-text
      +media(pc)
        margin-right 19px
      +media(sp)
        margin-right 14px
    .btn-icon
      svg
        transform rotate(-90deg)
        margin-left -6px
  .btn-list
    absolute top 50% left 50%
    +media(pc)
      size 40
      margin-top -20px
      margin-left -20px
    +media(sp)
      size 27
      margin-top -13px
      margin-left -14px

//- ----------------------------------------------------------------
//- SHARE
//- ----------------------------------------------------------------
.share
  margin 0 80px 80px
  position relative
  padding 47px 0 49px
  text-align center
  background #f6f5f0
  font-weight bold
  box-sizing border-box
  .heading
    margin-bottom 14px
    font-size-setting-pc(24,24)
  .lead
    margin 0 80px 23px
    font-size-setting-pc(16,28)
    letter-spacing .03em
  .buttons
    display flex
    justify-content center
    a
      position relative
      display flex
      align-items center
      justify-content center
      height 74px
      color #fff
      width get-percent-size(318, 800)
      margin 0 6px
      font-size-setting-pc(16,16)
      transition .3s
      .text
        position relative
        display inline-block
        z-index 1
        &::before
          absolute top left
          content ""
          display block
      .bg
        absolute top left
        size 100%
        background #000
        &::before
        &::after
          content ""
          absolute top left
          size 100%
          transition .3s
        &::before
          opacity 1
        &::after
          opacity 0
      &:hover
        .bg
          &::before
            opacity 0
          &::after
            opacity 1
      &.button-tw
        .text
          padding-left 45px
          &::before
            top -3px
            size 24 20
            bg-img("../../../../images/icon-tw.png", center, top, no-repeat, ,cover)
      &.button-fb
        .text
          padding-left 36px
          &::before
            top -5px
            size 12 24
            bg-img("../../../../images/icon-fb.png", center, top, no-repeat, ,cover)
  +media('sp')
    margin 0 20px 30px
    padding 38px 0 40px
    .heading
      margin-bottom 14px
      font-size-setting-sp(36,36)
    .lead
      margin 0 25px 13px
      font-size-setting-sp(28,46)
    .buttons
      display block
      margin 0 20px
      a
        height 52px
        width 100%
        margin 0 0 10px
        font-size-setting-sp(24,24)
        .bg
          &::before
          &::after
            display none
        &.button-tw
          .text
            padding-left 31px
            &::before
              top 0
              size 17 14
              background-size cover
        &.button-fb
          margin-bottom 0
          .text
            padding-left 23px
            &::before
              top -1px
              size 8 17
              background-size cover