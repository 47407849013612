//- ---------------------------------------------------------------
//- ログイン
//- ---------------------------------------------------------------
.page-search

  .page-heading
    +media(pc)
      height 384px
    +media(sp)
      height (409/2)px
    .ja
      letterspacing(25)
      font-weight bold
      +media(pc)
        margin-top 5px
        font-rem(38)
      +media(sp)
        font-rem(21)

  .page-contents
    +media(pc)
      padding-top 21px
      padding-bottom 160px
    +media(sp)
      padding-top 14px
      padding-bottom 100px

    .category-title
      font-family $font-notosansjp-yakuhan
      margin-left -.1em
      +media(pc)
        padding-bottom 45px
      +media(sp)
        padding-bottom 45px
      .color-red
        font-weight bold
        color $color-red
      .small
        font-weight 400
        .color-red
          margin-left .5em
        +media(pc)
          margin-left 10px
          font-rem(18,,50)
        +media(sp)
          display block
          margin-top 9px
          font-rem(16,,50)


    .list-search-article
      border-top 1px solid #dcdcdc
      li
        border-bottom 1px solid #dcdcdc
        a
          display flex
          color #000
          hover-opacity()
          +media(pc)
            justify-content space-between
            align-items center
            padding-top 43px
            padding-bottom 44px
          +media(sp)
            flex-direction column
            align-items flex-end
            padding-top 29px
            padding-bottom 28px
        .contents
          letterspacing(25)
          +media(pc)
            width 760px
          +media(sp)
            width 100%
          .title
            font-weight bold
            +media(pc)
              font-rem(22,32)
              text-line-1()
            +media(sp)
              font-rem(17,28)
          .text
            text-align justify
            +media(pc)
              margin-top 17px
              font-rem(13,26)
            +media(sp)
              margin-top 16px
              font-rem(12,21)
        .btn-viewall
          +media(pc)
            margin-top 46px
          +media(sp)
            margin-top 19px

