//- ---------------------------------------------------------------
//- イベント
//- ---------------------------------------------------------------

.page-event

  .page-container
    +media(pc)
      padding-bottom 110px
    +media(sp)
      padding-bottom 76px

  .local-nav
    li
      +media(pc)
        margin-right 57px

  //- Schedule スケジュール
  .section-schedule
    .section-inner
      +media(pc)
        padding-bottom 28px
      +media(sp)
        padding-bottom 36px
    .calendar
      +media(pc)
        margin-top 31px
      +media(sp)
        margin-top 33px
      .eo-fullcalendar
        font-roboto()
        h2
          margin 0
          font-weight bold
          +media(pc)
            font-rem(32,,10)
          +media(sp)
            margin-left -2px
            font-rem(27,,-20)
            text-align left
        button
          border none
          outline none
          padding 0
          cursor pointer
          background none
          border-radius 0
          font-roboto()
          box-sizing border-box
          -webkit-tap-highlight-color rgba(0,0,0,0)
        .fc-toolbar
          width 100%
          display flex
          justify-content space-between
        .fc-left
        .fc-right
          float none
        .fc-center
        .fc-clear
          display none
        .fc-right
          display flex
          +media(pc)
            margin-top 18px
          +media(sp)
            margin-top 12px
        .fc-button-group
          margin 0
          &:nth-child(3)
            order 1
            +media(pc)
              margin-right 19px
            +media(sp)
              margin-right 15px
          &:nth-child(1)
          &:nth-child(2)
            order 2
          &:nth-child(1)
            +media(pc)
              margin-right 10px
            +media(sp)
              margin-right 5px
          &:nth-child(2)
            +media(pc)
              margin-right 1px
        .fc-today-button
          color #000
          background #fff
          border 1px solid #dcdcdc
          border-radius 5px
          box-sizing border-box
          opacity 1
          +media(pc)
            size 100 34
            padding-left 4px
            font-rem(14,,100)
          +media(sp)
            size 50 28
            padding-left 4px
            font-rem(10,,100)
        .fc-prev-button
        .fc-next-button
          position relative
          background #fff
          border 1px solid #dcdcdc
          border-radius 50%
          +media(pc)
            size 34
          +media(sp)
            size 28
          .fc-icon
            display none
          &::before
            display block
            content ""
            bg-img($image-path + "icon-arrow.svg")
            background-size cover
            +media(pc)
              size 12 7
            +media(sp)
              size 10 6
        .fc-prev-button
          &::before
            transform rotate(90deg)
            +media(pc)
              absolute top 13px left 10px
            +media(sp)
              absolute top 10px left 8px
        .fc-next-button
          &::before
            transform rotate(-90deg)
            +media(pc)
              absolute top 13px right 10px
            +media(sp)
              absolute top 10px right 8px
        .fc-view-container
          +media(pc)
            margin-top 37px
          +media(sp)
            margin-top 18px
        td,
        th
          color #655d63
          border-color #dfd3d8
        .fc-day-header
          color #fff
          background $color-red
        .fc-day-number
          padding-right 2px !important