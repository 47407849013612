if !support-android4
  vendors = official
else
  vendors = official webkit

@keyframes delayView
  0%
    opacity 0
  99.999%
    opacity 0
  100%
    opacity 1

@keyframes fadeIn
  0%
    opacity 0
  100%
    opacity 1

@keyframes fadeOut
  0%
    opacity 1
  100%
    opacity 0

@keyframes arrow
  0%
    height 0
  100%
    height 100%